import { graphql } from 'gatsby';
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import Card from "../../components/Card/Card";
import Pagination from "../../components/Common/Pagination";
import Layout from "../../components/Layout";
import SideNav from "../../components/SideNav/SideNav";
import { getNews, getNewsDetails } from "../../reducers/News/actions";
import { urlDescription } from '../../utils/pageDescription';
import "./styles.scss";

const News = ({ page, getNews, news, totalPages, data }) => {

  useEffect(() => {
    getNews({ page, ItemsPerPage: 1000 })
  }, [])

  const handlePageClick = () => {
    getNews({ page });
  }

  const nodes = data.allStrapiNews.edges
  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  let newNodes = []

  if (news.length > 0) {
    newNodes = news && nodes.slice().map((newNode, i) => {
      if (newNode.node.id === news[i].id) {
        return newNode
      } else {
        let properNode = nodes.find(n => n.node.id === news[i].id)
        return properNode
      }
    });
  }

  return (
    <Layout description={urlDescription[url.pathname]}>
      <div className="building-library-title">
        <h1 className="h2 font-weight-bold">Полезна информация</h1>
      </div>
      <div className="news-wrapper">
        <div className="news-page-card-container">
          <div className="news-page-title">
            <h2 className="h3 font-weight-bold">{url.pathname === '/news' ? 'Новини' : 'Статии'}</h2>
          </div>
          <Row className="news-page-card-row">
            <Col className='mb-5 sidenav-menu-col' xs={12} md={4} lg={4}>
              <SideNav news active={url.pathname === '/news' ? 'news' : "articles"} />
            </Col>
            <Col className='news-page-card-column' xs={12} md={8} lg={8} >
              <Row className="mb-5">
                {newNodes.length > 0 && newNodes.map((n, i) => {
                  return (
                    (i === 0)
                      ? <Col
                        key={i}
                        className="px-xs-0 px-md-3"
                        xs={12}
                      >
                        <Card
                          isGatsbyImage
                          cardClass="new-horizontal-card"
                          imageClass="new-horizontal-card-img"
                          cardBodyClass="news-page-horizontal-card-body"
                          btnClass="news-page-horizontal-card-btn"
                          title={n.node.title}
                          desc={`${n.node.description && n.node?.description?.substr(0, 100)}...`}
                          image={n.node.image.localFile.childImageSharp}
                          url={`/news-single/${n.node.id}`}
                        // onClick={() => getNewsDetails({ id: n.node.id })}
                        />
                      </Col>
                      : <Col
                        key={i}
                        className="p-xs-0 px-md-3"
                        xs={12}
                        lg={6}
                      >
                        <Card
                          isGatsbyImage
                          cardClass="mt-5 new-vertical-card"
                          imageClass="new-vertical-card-img"
                          cardBodyClass="news-page-vertical-card-body"
                          btnClass="news-page-horizontal-card-btn"
                          title={n?.node.title}
                          desc={`${n.node.description && n.node.description.substr(0, 100)}...`}
                          image={n.node.image.localFile.childImageSharp}
                          url={`/news-single/${n.node.id}`}
                        // onClick={() => getNewsDetails({ id: n.node.id })}
                        />
                      </Col>
                  )
                })}
              </Row>
            </Col>
            <Row className="w-100 justify-content-center">
              <div>
                {
                  totalPages && totalPages > 1
                    ? <Pagination
                      handlePageClick={handlePageClick}
                      pageCount={totalPages}
                      currentPage={page}
                    />
                    : null
                }
              </div>
            </Row>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    news: state.news.data,
    page: state.news.current_page,
    totalPages: state.news.total_pages
  };
};

const mapDispatchToProps = {
  getNews,
  getNewsDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(News);

export const query = graphql`
query newsQuery {
  allStrapiNews {
    edges {
      node {
        id: strapiId
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            publicURL
          }
          url
        }
        title
        description
      }
    }
  }
}`
